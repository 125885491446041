// eslint-disable-next-line
const FirebaseConfig = {
  apiKey: "AIzaSyAGPd9MkcHEDtOvAiVOY4f5yRJSnhrxtb8",
  authDomain: "reven-22e54.firebaseapp.com",
  databaseURL: "https://reven-22e54-default-rtdb.firebaseio.com",
  projectId: "reven-22e54",
  storageBucket: "reven-22e54.appspot.com",
  messagingSenderId: "855886372751",
  appId: "1:855886372751:web:623875216493e3c24d75fa",
  measurementId: "G-FYEP5SH46G"
};

const FirebaseConfig_Dev = {
  apiKey: "AIzaSyCtTpUPGXXwsevxz8B3a82c6AuOhxpm6bM",
  authDomain: "reven-dev.firebaseapp.com",
  databaseURL: "https://reven-dev-default-rtdb.firebaseio.com/",
  projectId: "reven-dev",
  storageBucket: "reven-dev.appspot.com",
  messagingSenderId: "513016310873",
  appId: "1:513016310873:web:75a1423ec4a0efa13c9548",
  measurementId: "G-FYEP5SH46G"
};

export default process.env.REACT_APP_STAGE === "production" ? FirebaseConfig : FirebaseConfig_Dev;
