import { AUTH_PREFIX_PATH, REDIRECT_URL_KEY, UNAUTHENTICATED_ENTRY } from "configs/AppConfig";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const lockedValue = localStorage.getItem("LOCKSCREEN");
  if (!token) {
    return (
      <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />
    );
  } else if (lockedValue) {
    return <Navigate to={`${AUTH_PREFIX_PATH}/lock-screen?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
