import { ADMIN_ENTRY, AUTHENTICATED_ENTRY, PHYSICIAN_ENTRY } from "configs/AppConfig";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const User_Details = JSON.parse(localStorage.getItem("USER_DETAILS") || "{}");
  return token ? <Navigate to={User_Details?.isSuperAdmin ? ADMIN_ENTRY : User_Details?.isPhysician ? PHYSICIAN_ENTRY : AUTHENTICATED_ENTRY} /> : <Outlet />;
  // return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet />;
};

export default PublicRoute;
